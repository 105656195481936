import styled from 'styled-components';

import { breakpoints, colours } from '../../../../components/styled/variables';
import { Section } from '../../../../components/styled/section';
import { TwoStories } from '../../../../components/customer-stories-links/styled/lean-story-card';
import { Link } from '../../../../components';

export const CountryName = styled.span`
  @media (max-width: ${breakpoints.tabletPortrait}) {
    display: none;
  }
`;

export const TwoStoriesStyled = styled(TwoStories)`
  @media (min-width: ${breakpoints.tabletPortrait}) {
    margin-right: 50px;
  }
`;
export const AddonsContainer = styled(Section)<{
  addonsBg?: string;
  addonsBgMobile?: string;
}>`
  background-image: url(${props => props.addonsBgMobile});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${colours.blue};
  max-width: 1248px;
  border-radius: 0;
  padding: 20px;
  position: relative;
  z-index: 1;

  @media (min-width: ${breakpoints.tablet}) {
    background-image: url(${props => props.addonsBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    border-radius: 16px;
    padding: 20px 40px 80px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 20px 80px 80px;
  }
`;

export const AddonsList = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: top;
  padding: 0;
  margin: 0;

  @media (max-width: ${breakpoints.largeMobile}) {
    flex-flow: column nowrap;
    align-items: flex-start;
  }

  @media (max-width: ${breakpoints.tablet}) {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
`;

export const AddonsListItem = styled.li`
  width: auto;
  margin: 0 20px;
  line-height: 24px;
  flex: 1;

  &:first-child {
    margin-left: 0;
  }

  @media (max-width: ${breakpoints.largeMobile}) {
    margin: 20px 0;
  }

  @media (max-width: ${breakpoints.tablet}) {
    margin: 20px 0;
  }
`;

export const AddonsHeadline = styled.h2`
  color: ${colours.white};
  font-weight: 900;
  font-size: 48px;
  line-height: 60px;
  width: 300px;

  @media (max-width: ${breakpoints.largeMobile}) {
    width: auto;
    font-size: 36px;
    line-height: 44px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: 300px;
    font-size: 36px;
    line-height: 44px;
  }
`;

export const AddonsTitle = styled.h3`
  color: ${colours.white};
  font-weight: 900;
  font-size: 18px;
  margin: 0;
`;

export const AddonsDescription = styled.p`
  color: ${colours.white};
  font-size: 16px;
  margin: 10px 0;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: 14px;
  }
`;

export const LearnMoreLink = styled(Link)`
  color: ${colours.white};
  font-size: 16px;
  font-weight: 700;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: 14px;
  }
`;

export const MarketAvailabilityTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: auto 0 auto auto;

  thead {
    tr {
      border-bottom: 1px solid #ececec;
      line-height: 34px;
    }
    th:first-child {
      text-align: left;
      font-weight: 900;
      font-size: 20px;
    }
    th {
      text-align: center;
    }
  }

  tbody:before {
    line-height: 16px;
    content: '-';
    color: white;
    display: block;
  }

  tbody {
    td:first-child {
      text-align: left;
      width: 40%;
      img {
        vertical-align: sub;
        margin-right: 12px;
      }
    }
    td {
      text-align: center;
      line-height: 40px;
      width: 20%;

      span:nth-child(2) {
        white-space: nowrap;
      }

      svg {
        vertical-align: middle;
      }

      @media (max-width: ${breakpoints.tablet}) {
        font-size: 14px;
        width: 10%;
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    max-width: 630px;
  }
`;

export const StatusBadge = styled.div`
  color: ${colours.black};
  height: 20px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
  width: 84px;
  margin: auto;
`;
