import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  AddonsList,
  AddonsListItem,
  AddonsContainer,
  AddonsTitle,
  AddonsDescription,
  AddonsHeadline,
  LearnMoreLink,
} from '../styled';

interface AddonsFeature {
  title: string;
  description: string;
  href?: string;
}

const addonsFeatures: AddonsFeature[] = [
  {
    title: 'Offers API',
    description:
      'Build card-linked offers with flexible qualification parameters to create engaging experiences for your customers.',
    href: '/products/add-ons#offers',
  },
  {
    title: 'Card Enrollment SDKs',
    description:
      'Securely and easily enroll users into your program, even if you are not PCI compliant. Available for Web, iOS, Android and React Native.',
  },
  {
    title: 'Content Marketplace',
    description:
      'Tap into Fidel API’s collection of offers to give your users even more value and boost engagement.',
  },
];

function Addons() {
  const { addonsBg, addonsBgMobile } = useStaticQuery(assetsQuery);
  const addonsBgImage =
    addonsBg.childImageSharp.gatsbyImageData.images.fallback.src;
  const addonsBgMobileImage =
    addonsBgMobile.childImageSharp.gatsbyImageData.images.fallback.src;

  return (
    <AddonsContainer
      addonsBg={addonsBgImage}
      addonsBgMobile={addonsBgMobileImage}
    >
      <AddonsHeadline>Build even more with add-ons</AddonsHeadline>
      <AddonsList>
        {addonsFeatures.map((feature, id) => (
          <AddonsListItem key={id}>
            <AddonsTitle>{feature.title}</AddonsTitle>
            <AddonsDescription>{feature.description}</AddonsDescription>
            {feature.href && (
              <LearnMoreLink to={feature.href}>Learn more</LearnMoreLink>
            )}
          </AddonsListItem>
        ))}
      </AddonsList>
    </AddonsContainer>
  );
}

const assetsQuery = graphql`
  {
    addonsBg: file(relativePath: { eq: "products/addons-bg.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1248, placeholder: NONE, layout: FIXED)
      }
    }
    addonsBgMobile: file(
      relativePath: { eq: "products/addons-bg-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 361, placeholder: NONE, layout: FIXED)
      }
    }
  }
`;

export default Addons;
