import React from 'react';

import { Logo, LogoContainer } from './styled/lean-story-card';
import {
  BigContainer,
  BigText,
  Representative,
  RepresentativeText,
} from './styled/big-story-card';

interface BigStoryCardProps {
  customer: string;
  background: string;
  representative: string;
  text: string;
  logoWidth: number;
}

const BigStoryCard: React.FC<BigStoryCardProps> = ({
  customer,
  background,
  representative,
  text,
  logoWidth,
}) => {
  const { srcLogo, srcSetLogo } = getLogo(customer);
  const { srcRepresentative, srcSetRepresentative } =
    getRepresentative(customer);

  return (
    <BigContainer
      to={`/stories/${customer.toLowerCase()}`}
      style={{ background }}
    >
      <BigText>{text}</BigText>
      <LogoContainer>
        <Representative>
          <img
            src={srcRepresentative}
            srcSet={srcSetRepresentative}
            role="presentation"
          />
          <RepresentativeText>{representative}</RepresentativeText>
        </Representative>
        <Logo style={{ width: `${logoWidth}px` }}>
          <img
            src={srcLogo}
            srcSet={srcSetLogo}
            className="story-card-logo"
            role="presentation"
          />
        </Logo>
      </LogoContainer>
    </BigContainer>
  );
};

function getLogo(customer: string) {
  const { src, srcSet } = getAsset(
    `small_thumb_${customer.toLowerCase()}_logo`,
  );
  return { srcLogo: src, srcSetLogo: srcSet };
}

function getRepresentative(customer: string) {
  const { src, srcSet } = getAsset(`${customer.toLowerCase()}_representative`);
  return { srcRepresentative: src, srcSetRepresentative: srcSet };
}

function getAsset(assetName: string) {
  const src = require(`../../modules/stories/images/${assetName}.png`);
  const src2x = require(`../../modules/stories/images/${assetName}@2x.png`);
  const srcSet = `${src.default}, ${src2x.default} 2x`;

  return { src, srcSet };
}

export default BigStoryCard;
