import React from 'react';
import { OverlayTrigger } from '../../../../components';

import {
  Block,
  FeatureCTA,
  Flex,
  Heading,
  Section,
} from '../../../../components/styled';
import { colours } from '../../../../components/styled/variables';
import ArrowIcon from '../../../../static/icons/arrow.svg';
import AvailabilityTable from './availability-table';

export default function MarketAvailability() {
  return (
    <Section
      bgColor={colours.lightGrey2}
      maxWidth={1248}
      padding="0px 24px 64px 24px"
      paddingTablet="50px 40px 64px 40px"
      paddingDesktop="50px 80px 100px 80px"
    >
      <Block margin="auto">
        <Flex columns={2} alignItems="stretch">
          <Block maxWidth="336px" margin="0 20px 0 0">
            <Heading size={48} weight={900} lineHeight="60px">
              Availability by market
            </Heading>

            <p style={{ marginBottom: 0 }}>
              Fidel API is rapidly expanding service coverage around the globe.
              If you’re interested in working together, let us know.
            </p>
            <Block margin="32px auto">
              <FeatureCTA
                id="contactSales-select-availabilitySection"
                as={OverlayTrigger}
                target="contact_sales"
                variant="secondary"
              >
                Get in touch <ArrowIcon />
              </FeatureCTA>
            </Block>
          </Block>
          <Flex flow="column" style={{ width: '100%' }}>
            <AvailabilityTable type="live" />
            <Block margin="30px 0 0 0">
              <AvailabilityTable type="beta" />
            </Block>
          </Flex>
        </Flex>
      </Block>
    </Section>
  );
}
