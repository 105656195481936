export type Status = 'available' | 'unavailable' | 'underContact';

export type Scheme = 'mastercard' | 'visa' | 'amex';

export type Country = {
  key: string;
};

export type CountryAvailability = {
  country: string;
  countryCode: string;
  isLive: boolean;
  availability?: Record<Scheme, Status> | undefined;
};

const availabilityList: ReadonlyArray<CountryAvailability> = [
  {
    country: 'United Kingdom',
    countryCode: 'GB',
    isLive: true,
    availability: {
      visa: 'available',
      mastercard: 'available',
      amex: 'available',
    },
  },
  {
    country: 'Ireland',
    countryCode: 'IE',
    isLive: true,
    availability: {
      visa: 'available',
      mastercard: 'available',
      amex: 'available',
    },
  },
  {
    country: 'United States',
    countryCode: 'US',
    isLive: true,
    availability: {
      visa: 'available',
      mastercard: 'available',
      amex: 'available',
    },
  },
  {
    country: 'Canada',
    countryCode: 'CA',
    isLive: true,
    availability: {
      visa: 'available',
      mastercard: 'available',
      amex: 'unavailable',
    },
  },
  {
    country: 'Sweden',
    countryCode: 'SE',
    isLive: true,
    availability: {
      visa: 'available',
      mastercard: 'underContact',
      amex: 'available',
    },
  },
  {
    country: 'United Arab Emirates',
    countryCode: 'AE',
    isLive: true,
    availability: {
      visa: 'available',
      mastercard: 'underContact',
      amex: 'unavailable',
    },
  },
  {
    country: 'Japan',
    countryCode: 'JP',
    isLive: false,
  },
];

export default availabilityList;
