import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Layout, SEO } from '../../../layout';
import CodeSymbolIcon from '../assets/code-symbol.svg';
import ShieldPlusIcon from '../assets/shield_plus.svg';
import Notification from '../assets/notification.svg';
import DashboardFeatures from '../common/components/dashboard-features';
import Addons from './components/add-ons';
import MarketAvailability from './components/market-availability';
import SideBySideSection from '../../../components/side-by-side-section';
import { colours } from '../../../components/styled/variables';
import { DevelopersList } from '../../index/styled';
import txObject from '../code-blocks/tx-object';
import { CodeWindow } from '../../../components';
import BigStoryCard from '../../../components/customer-stories-links/big-story-card';
import { CTAContainer, FeatureCTA, Section } from '../../../components/styled';
import LeanStoryCard from '../../../components/customer-stories-links/lean-story-card';
import { TwoStoriesStyled } from './styled';
import ArrowIcon from '../../../static/icons/arrow.svg';
import HeroSection, { HeroFeature } from '../common/components/hero-section';

export default function SelectTransactions() {
  const { heroImg, heroImgMobile } = useStaticQuery(assetsQuery);

  const heroImage = heroImg.childImageSharp.gatsbyImageData.images.fallback.src;
  const heroImageMobile =
    heroImgMobile.childImageSharp.gatsbyImageData.images.fallback.src;

  const heroFeatures: HeroFeature[] = [
    {
      icon: <Notification />,
      title: 'Real-time messaging',
      description:
        'Through one integration, get select payment events from Visa, Mastercard and American Express in real time.',
    },
    {
      icon: <CodeSymbolIcon />,
      title: 'Enriched payment events',
      description:
        'Build on top of enriched, highly accurate data to create a valuable payment experience your users can rely on.',
    },
    {
      icon: <ShieldPlusIcon />,
      title: 'A secure user experience',
      description:
        'Protect customers with a consent-based approach. Safeguard user information with Fidel API’s secure tokenization engine.',
    },
  ];

  return (
    <Layout>
      <SEO
        title="Select Transactions API"
        description="Create custom payment experiences to engage with users and power your business."
      />
      <HeroSection
        id="productSelect"
        title="Select Transactions API"
        subtitle="Get real-time payment events from select brands. Use granular location data to create engaging, personalized experiences."
        images={{
          desktop: heroImage,
          mobile: heroImageMobile,
        }}
        features={heroFeatures}
      />
      <DashboardFeatures
        title=" More than an API"
        subtitle="Build on top of the Select Transactions API to create valuable and engaging experiences for your customers. Leverage the Fidel API dashboard to easily manage your program."
        programType="select-transactions"
      />
      <SideBySideSection
        bgColor={colours.primary}
        sideContentPosition="left"
        inverted
        heading="Data for developers"
        surtitle="Clean code"
        flexAlignItems="stretch"
        content={
          <>
            <p style={{ color: colours.white }}>
              Compress years of work into days. Through a single integration,
              Fidel API enables you to access network data and create engaging
              user experiences.
            </p>
            <p style={{ color: colours.white }}>
              Get enriched, granular transaction messaging the moment a purchase
              is made.
            </p>
            <DevelopersList style={{ fontSize: '14px' }}>
              <li>+ Location</li>
              <li>+ Brand name</li>
              <li>+ Date and time</li>
              <li>+ Amount and currency</li>
              <li>+ Card network</li>
            </DevelopersList>
            <CTAContainer>
              <FeatureCTA
                id="dashboardSignUp-productSelect-codeSection"
                as="a"
                href="https://dashboard.fidel.uk/sign-up"
                rel="noopener noreferrer"
                target="_blank"
                variant="secondary"
              >
                Get API key <ArrowIcon />
              </FeatureCTA>
              <FeatureCTA as="a" href="/docs" variant="border">
                Go to docs
              </FeatureCTA>
            </CTAContainer>
          </>
        }
        sideContent={
          <CodeWindow title="Fidel API Transaction Object" value={txObject} />
        }
      />
      <SideBySideSection
        surtitle="Client solutions"
        heading="What will you build?"
        sideContentPosition="left"
        content={
          <>
            <p style={{ marginBottom: 0 }}>
              Check out some inspiration from Fidel API customers and learn more
              about how they streamlined their businesses with the Select
              Transactions API.
            </p>
            <FeatureCTA
              style={{ marginTop: '24px' }}
              variant="secondary"
              as="a"
              href="/stories"
            >
              Customer stories <ArrowIcon />
            </FeatureCTA>
          </>
        }
        sideContent={
          <TwoStoriesStyled>
            <LeanStoryCard
              customer="topcashback"
              background="#12986D"
              stat="3x"
              text="TopCashback tripled their customer base size in 3 months."
              logoWidth={100}
            />
            <LeanStoryCard
              customer="miconex"
              background="#E5128D"
              stat="31%"
              text="Mi Rewards increased their number of transactions by over 31%."
              logoWidth={105}
            />
          </TwoStoriesStyled>
        }
      />
      <Section maxWidth={1088}>
        <BigStoryCard
          customer="perkbox"
          background="#00ADEE"
          logoWidth={106}
          representative="Laura Camfield, Product Manager at Perkbox"
          text="Without Fidel API’s technology, we would have no way to include local, small and medium businesses on our platform."
        />
      </Section>

      <MarketAvailability />
      <Addons />
    </Layout>
  );
}

const assetsQuery = graphql`
  {
    heroImg: file(relativePath: { eq: "products/select-transactions-bg.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1248, placeholder: NONE, layout: FIXED)
      }
    }
    heroImgMobile: file(
      relativePath: { eq: "products/select-transactions-bg-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 361, placeholder: NONE, layout: FIXED)
      }
    }
  }
`;
