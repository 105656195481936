export default `{
  "accountId": "4ca457bc-9092-4865-8108-123456789abc",
  "amount": 5.44,
  "auth": true,
  "authCode": "01107I",
  "brand": {
    "id": "3a4fcc58-4913-48c2-8f36-123456789abc",
    "logoURL": "https://www.riteaid.com/content/dam/riteaid-web/brand-identity/logos/rite-aid/web/RAD-Logo-RGB-large-square-open-graph.png",
    "metadata": null,
    "name": "Rite Aid"
  },
  "card": {
    "firstNumbers": "412345",
    "id": "26d81eb9-ba38-4081-89f8-123456789abc",
    "lastNumbers": "1234",
    "scheme": "visa",
    "metadata": {
        "userId": "abc123456"
    },
  },
  "cardPresent": true,
  "cleared": false,
  "created": "2022-02-23T21:17:04.650Z",
  "currency": "USD",
  "datetime": "2022-02-23T16:17:03",
  "id": "16154cba-2258-41d4-beaf-123456789abc",
  "identifiers": {
    "MID": "vop-28085064-123456789",
    "amexApprovalCode": null,
    "mastercardAuthCode": null,
    "mastercardRefNumber": null,
    "mastercardTransactionSequenceNumber": null,
    "visaAuthCode": "01107I"
  },
  "location": {
    "address": "1849 2nd Ave",
    "city": "New York",
    "countryCode": "USA",
    "geolocation": {
      "latitude": 40.7841187,
      "longitude": -73.9474606
    },
    "id": "71f9a2db-0df5-4645-9dfe-123456789abc",
    "metadata": null,
    "postcode": "10128",
    "state": null,
    "timezone": "America/New_York"
  },
  "offer": null,
  "programId": "efd1eef1-f4f4-49ba-8241-123456789abc",
  "updated": "2022-02-23T21:17:05.029Z",
  "wallet": null
}`;
