import { Link } from 'gatsby';
import styled from 'styled-components';

import { breakpoints } from '../../styled/variables';

export const Container = styled(Link as any)`
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 24px 36px;
  border-radius: 16px;
  border: none;
  :after {
    display: none;
  }

  svg {
    position: relative;
    left: 0;
    transition: left 0.3s ease-out;
  }

  &:hover,
  &:focus {
    svg {
      left: 6px;
      transition: left 0.3s ease-in;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    max-width: 280px;
  }
`;

export const Stat = styled.h1`
  font-size: 72px;
  font-weight: 900;
  color: white;
  margin: 0;
`;

export const Text = styled.p`
  font-size: 24px;
  line-height: 32px;
  color: white;
  margin: 0;
  font-weight: bold;
`;

export const Logo = styled.div`
  width: 44px;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin-top: 30px;
`;

export const TwoStories = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 40px;

  ${Container} {
    position: relative;
    top: 30px;
    margin-left: 0;
  }

  > :first-child {
    top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 50px;
    width: 50%;

    ${Container} {
      top: 60px;
      margin-left: 20px;
      top: 60px;
    }

    > :first-child {
      top: 0;
      margin-left: 0;
    }

    > :first-child {
      top: 0;
    }
  }
`;
