import React from 'react';

import { CountryFlag } from '../../../../components';
import Check from '../../../../../static/check_circle.svg';
import Amex from '../../assets/amex.svg';
import Mastercard from '../../assets/mastercard.svg';
import Visa from '../../assets/visa.svg';
import marketAvailability, { Status } from '../features/market-availability';
import { MarketAvailabilityTable, StatusBadge, CountryName } from '../styled';
import { Flex } from '../../../../components/styled';

type AvailabilityTableProps = {
  type: 'live' | 'beta';
};
export default function AvailabilityTable({ type }: AvailabilityTableProps) {
  const renderAvailabilityStatus = (status?: Status) => {
    switch (status) {
      case 'available':
        return <Check />;

      case 'underContact':
        return <StatusBadge>Talk to us</StatusBadge>;

      default:
        return <StatusBadge>Unavailable</StatusBadge>;
    }
  };

  if (type === 'beta')
    return (
      <MarketAvailabilityTable>
        <thead>
          <tr>
            <th>In Beta</th>
          </tr>
        </thead>
        <tbody>
          {marketAvailability
            .filter(item => !item.isLive)
            .map(({ country, countryCode }, index) => (
              <tr key={index}>
                <td>
                  <Flex justifyContent="flex-start" breakFlow="nowrap">
                    <CountryFlag
                      code={countryCode}
                      name={country}
                      rounded={false}
                    />
                    <CountryName>{country}</CountryName>
                  </Flex>
                </td>
              </tr>
            ))}
        </tbody>
      </MarketAvailabilityTable>
    );

  return (
    <MarketAvailabilityTable>
      <thead>
        <tr>
          <th>Live</th>
          <th>
            <Visa />
          </th>
          <th>
            <Mastercard />
          </th>
          <th>
            <Amex />
          </th>
        </tr>
      </thead>
      <tbody>
        {marketAvailability
          .filter(item => item.isLive)
          .map(({ country, countryCode, availability }, index) => (
            <tr key={index}>
              <td>
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'wrap',
                  }}
                >
                  <CountryFlag
                    code={countryCode}
                    name={country}
                    rounded={false}
                  />
                  <CountryName>{country}</CountryName>
                </div>
              </td>
              <td>{renderAvailabilityStatus(availability?.visa)}</td>
              <td>{renderAvailabilityStatus(availability?.mastercard)}</td>
              <td>{renderAvailabilityStatus(availability?.amex)}</td>
            </tr>
          ))}
      </tbody>
    </MarketAvailabilityTable>
  );
}
