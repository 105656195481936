import React from 'react';

import {
  Container,
  Logo,
  LogoContainer,
  Stat,
  Text,
} from './styled/lean-story-card';
import ArrowIcon from '../../static/icons/arrow.svg';

interface LeanStoryCardProps {
  customer: string;
  background: string;
  stat: string;
  text: string;
  logoWidth: number;
}

const LeanStoryCard: React.FC<LeanStoryCardProps> = ({
  customer,
  background,
  stat,
  text,
  logoWidth,
}) => {
  const { srcLogo, srcSetLogo } = getLogo(customer);

  return (
    <Container to={`/stories/${customer.toLowerCase()}`} style={{ background }}>
      <Stat>{stat}</Stat>
      <Text>{text}</Text>
      <LogoContainer>
        <Logo style={{ width: `${logoWidth}px` }}>
          <img
            src={srcLogo}
            srcSet={srcSetLogo}
            className="story-card-logo"
            role="presentation"
          />
        </Logo>
        <ArrowIcon style={{ fill: 'white' }} />
      </LogoContainer>
    </Container>
  );
};

function getLogo(customer: string) {
  const { src, srcSet } = getAsset(`${customer.toLowerCase()}_logo`);
  return { srcLogo: src, srcSetLogo: srcSet };
}

function getAsset(assetName: string) {
  const src = require(`../../modules/stories/images/small_thumb_${assetName}.png`);
  const src2x = require(`../../modules/stories/images/small_thumb_${assetName}@2x.png`);
  const srcSet = `${src.default}, ${src2x.default} 2x`;

  return { src, srcSet };
}

export default LeanStoryCard;
