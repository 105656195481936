import styled from 'styled-components';
import { breakpoints, fonts } from '../../styled/variables';

import { Container, Text } from './lean-story-card';

export const BigContainer = styled(Container)`
  max-height: inherit;
  margin-top: 60px;

  @media (min-width: ${breakpoints.tablet}) {
    max-width: 100%;
    padding: 80px 94px;
    margin-top: 0;
  }
`;

export const BigText = styled(Text)`
  ${fonts.titles};
  font-size: 38px;
  line-height: 48px;

  @media (min-width: ${breakpoints.tabletPortrait}) {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const Representative = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;

  img {
    display: inline-block;
    margin-right: 24px;
  }
`;

export const RepresentativeText = styled.span`
  ${fonts.titles};
  font-weight: bold;
`;
